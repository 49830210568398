// @import "../../../../../global.scss";

// .attachment {
//   box-shadow: 2px 2px 4px gray;
//   padding-bottom: 5px;

//   .attachmentUpload {
//     background-color: #074173;
//     padding: 1.2rem;
//   }

//   .uploadbox {
//     display: flex;
//     margin: 2% 5% 2% 18%;
//     justify-content: space-between;
//   }
//   .inputbtn {
//     cursor: pointer;
//     border: none;
//     outline: none;
//     padding: 0.5rem 2rem;
//     background-color: lightgrey;
//   }
//   .uploadbtn {
//     border: none;
//     outline: none;
//     background-color: $primary-darkblue-color;
//     padding: 0.5rem 2rem;
//     color: white;
//     cursor: pointer;
//   }
//   .fileicon {
//     display: flex;
//     justify-content: center;
//   }
//   .categoryselect {
//     margin: 20px 50px 20px 10px;
//     height: 33px;
//     width: 150px;
//     border-radius: 5px;
//   }
//   .inputbtn {
//     cursor: pointer;
//     border: none;
//     border-radius: 5px;
//     outline: none;
//     padding: 0.5rem 1rem;
//     background-color: lightgrey;
//     margin: 20px 50px 20px 50px;
//     height: 18px;
//   }
//   .dropbtn {
//     background-color: #024f75;
//     color: rgb(245, 248, 248);
//     height: 25px;
//     width: 100px;
//     border-radius: 5px;
//     position: relative;
//     text-align: center;
//     display: inline-block;
//     margin: 20px 20px 20px 50px;
//     padding-top: 10px;
//   }
//   .dropdown-content {
//     display: inline-block;
//     position: absolute;
//     background-color: #f9f9f9;
//     min-width: 160px;
//     margin-left: 25px;
//     box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
//   }

//   .dropdown-content a {
//     color: black;
//     padding: 12px 16px;
//     text-decoration: none;
//     display: inline-block;
//     cursor: pointer;
//   }

//   .dropdown-content a:hover {
//     background-color: #f1f1f1;
//   }
//   .name {
//     height: 30px;
//     width: 140px;
//     border-radius: 5px;
//     margin: 20px;
//   }
//   .list {
//     display: block;
//     background-color: #074173;
//     color: azure;
//     height: 20px;
//     width: 90px;
//     text-align: center;
//     padding: 0.5rem 1rem;
//     margin-bottom: 20px;
//   }
//   .error-message {
//     width: 720px;
//     height: auto;
//     text-align: center;
//     margin-left: 13px;
//   }
//   .table {
//     border: 1px solid black;
//     box-shadow: 2px 2px 4px gray;
//     margin: 10px 16px 10px 11px;
//     padding: 0px 35px;
//     align-items: center;
//   }
//   th {
//     font-weight: 400;
//     color: grey;
//     text-align: center;
//   }
//   tr {
//     text-align: center;
//   }
//   th:first-child,
//   th:last-child {
//     width: 39%;
//   }

//   th:nth-child(2) {
//     width: 52%;
//   }
//   .icon {
//     margin: 0px 2px;
//     cursor: pointer;
//     border: 1px solid red;
//     border-radius: 50%;
//     padding: 4px;
//     position: relative;
//   }
  
// }






// @import "../../../../../global.scss";

// .attachment {
//   box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
//   padding: 20px;
//   border-radius: 8px;
//   background-color: #f9f9f9;

//   .attachmentUpload {
//     background-color: #074173;
//     padding: 1.5rem;
//     border-radius: 8px;
//     color: white;
//     font-weight: bold;
//     text-align: center;
//   }

//   .uploadbox {
//     display: flex;
//     margin: 2% 5%;
//     justify-content: space-between;
//     align-items: center;
//   }

//   .inputbtn, .uploadbtn {
//     cursor: pointer;
//     border: none;
//     outline: none;
//     padding: 0.5rem 2rem;
//     border-radius: 5px;
//     font-size: 1rem;
//   }

//   .inputbtn {
//     background-color: #e0e0e0;
//     margin: 0 10px;
//   }

//   .uploadbtn {
//     background-color: #024f75;
//     color: white;
//   }

//   .fileicon {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }

//   .categoryselect {
//     margin: 20px 10px;
//     height: 40px;
//     width: 200px;
//     border-radius: 5px;
//     border: 1px solid #ccc;
//     padding: 0 10px;
//   }

//   .dropbtn {
//     background-color: #024f75;
//     color: #f5f8f8;
//     height: 35px;
//     width: 120px;
//     border-radius: 5px;
//     text-align: center;
//     display: inline-block;
//     margin: 20px 10px;
//     padding-top: 5px;
//     font-size: 1rem;
//   }

//   .dropdown-content {
//     display: none;
//     position: absolute;
//     background-color: #f9f9f9;
//     min-width: 160px;
//     box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
//     z-index: 1;
//   }

//   .dropdown-content a {
//     color: black;
//     padding: 12px 16px;
//     text-decoration: none;
//     display: block;
//   }

//   .dropdown-content a:hover {
//     background-color: #f1f1f1;
//   }

//   .name {
//     height: 40px;
//     width: 200px;
//     border-radius: 5px;
//     margin: 20px;
//     border: 1px solid #ccc;
//     padding: 0 10px;
//   }

//   .list {
//     display: block;
//     background-color: #074173;
//     width: 120px;
//     text-align: center;
//     padding: 0.5rem 1rem;
//     margin-bottom: 20px;
//     border-radius: 5px;
//   }

//   .error-message {
//     width: 720px;
//     height: auto;
//     text-align: center;
//     margin-left: 13px;
//     color: red;
//     font-weight: bold;
//   }

//   .table {
//     border: 1px solid #ddd;
//     box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
//     margin: 10px 16px;
//     padding: 0 35px;
//     border-radius: 8px;
//     background-color: white;
//   }

//   th {
//     font-weight: 600;
//     color: #333;
//     text-align: center;
//     padding: 10px;
//   }

//   tr {
//     text-align: center;
//     border-bottom: 1px solid #ddd;
//   }

//   th:first-child, th:last-child {
//     width: 30%;
//   }

//   th:nth-child(2) {
//     width: 40%;
//   }

//   .icon {
//     margin: 0 5px;
//     cursor: pointer;
//     border: 1px solid #ccc;
//     border-radius: 50%;
//     padding: 4px;
//     position: relative;
//     background-color: #f9f9f9;
//   }
// }









// @import "../../../../../global.scss";
// .attachment {
//   box-shadow: 2px 2px 4px gray;
//   padding-bottom: 5px;

//   .attachmentUpload {
//     background-color: #074173;
//     padding: 1.2rem;
//   }

//   .uploadbox {
//     display: flex;
//     margin: 2% 5%;
//     justify-content: space-between;
//     align-items: center;
//   }

//   .inputbtn, .uploadbtn {
//     cursor: pointer;
//     border: none;
//     outline: none;
//     padding: 0.5rem 2rem;
//     border-radius: 5px;
//     font-size: 1rem;
//   }

//   .inputbtn {
//     background-color: #e0e0e0;
//     margin: 0 10px;
//   }

//   .uploadbtn {
//     background-color: #024f75;
//     color: white;
//   }

//   .fileicon {
//     display: flex;
//     justify-content: center;
//     align-items: center;
//   }

//   .categoryselect {
//     margin: 20px 10px;
//     height: 40px;
//     width: 200px;
//     border-radius: 5px;
//     border: 1px solid #ccc;
//     padding: 0 10px;
//     margin-right: 100px;
    
//   }

//   .dropbtn {
//     background-color: #024f75;
//     color: #f5f8f8;
//     height: 30px;
//     width: 120px;
//     border-radius: 5px;
//     text-align: center;
//     margin-bottom: 5px;
//     display: inline-block;
//     position: relative;
//     margin: 20px 20px;
//     padding-top: 10px;
//     font-size: 1rem;
//     translate: 30px;
//   }

//   .dropdown-content {
//     display: inline-block;
//     position: absolute;
//     background-color: #f9f9f9;
//     min-width: 160px;
//     box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
//     margin-left: 40px;
//     z-index: 1;
    
    
//   }

//   .dropdown-content a {
//     color: black;
//     padding: 12px 16px;
//     text-decoration: none;
//     display: inline-block;
//   }

//   .dropdown-content a:hover {
//     background-color: #f1f1f1;
   
//   }

//   .name {
//     height: 40px;
//     width: 200px;
//     border-radius: 5px;
//     margin: 20px;
//     border: 1px solid #ccc;
//     padding: 0 10px;
//   }

//   .list {
//     display: block;
//     background-color: #074173;
//     width: 120px;
//     text-align: center;
//     padding: 0.5rem 1rem;
//     margin-bottom: 20px;
//     border-radius: 5px;
//     color: white;
//   }

//   .error-message {
//     width: 720px;
//     height: auto;
//     text-align: center;
//     margin-left: 13px;
//     color: red;
//     font-weight: bold;
//   }

//   .table {
//     border: 1px solid #ddd;
//     box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
//     margin: 10px 16px;
//     padding: 0 35px;
//     border-radius: 8px;
//     background-color: white;
//     translate: 70px;
//     z-index: 0;
//   }

//   th {
//     font-weight: 600;
//     color: #333;
//     text-align: center;
//     padding: 10px;
//   }

//   tr {
//     text-align: center;
//     border-bottom: 1px solid #ddd;
//     font-size: smaller;
//   }
 
//   th:first-child, th:last-child {
//     width: 30%;
//     font-size: smaller;
//     translate: 20px;
//   }

//   th:nth-child(2) {
//     width: 40%;
//     font-size: smaller;
//     translate: 100px;
    
//   }

//   .icon {
//     margin: 0 5px;
//     cursor: pointer;
//     border: 1px solid #ccc;
//     border-radius: 50%;
//     padding: 4px;
//     position: relative;
//     background-color: #f9f9f9;
//     translate: 150px;
//   }

  


// }











@import "../../../../../global.scss";

.attachment {
  box-shadow: 2px 2px 4px gray;
  padding-bottom: 5px;

  .attachmentUpload {
    background-color: #074173;
    padding: 1.2rem;
  }

  .uploadbox {
    display: flex;
    margin: 2% 5%;
    justify-content: space-between;
    align-items: center;
  }

  .inputbtn, .uploadbtn {
    cursor: pointer;
    border: none;
    outline: none;
    padding: 0.5rem 2rem;
    border-radius: 5px;
    font-size: 1rem;
  }

  .inputbtn {
    background-color: #e0e0e0;
    margin: 0 10px;
  }

  .uploadbtn {
    background-color: #024f75;
    color: white;
  }

  .fileicon {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .categoryselect {
    margin: 20px 10px;
    height: 40px;
    width: 200px;
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: 0 10px;
    margin-right: 100px;
  }

  .dropbtn {
    background-color: #024f75;
    color: #f5f8f8;
    height: 30px;
    width: 120px;
    border-radius: 5px;
    text-align: center;
    margin-bottom: 5px;
    display: inline-block;
    position: relative;
    margin: 20px 20px;
    padding-top: 10px;
    font-size: 1rem;
    translate: 80px;
  }

  .dropdown-content {
    display: inline-block;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    margin-left: 40px;
    z-index: 1; /* Changed from -1 to 1 */
  }

  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: inline-block;
  }

  .dropdown-content a:hover {
    background-color: #f1f1f1;
  }

  .name {
    height: 40px;
    width: 200px;
    border-radius: 5px;
    margin: 20px;
    border: 1px solid #ccc;
    padding: 0 10px;
  }

  .list {
    display: block;
    background-color: #074173;
    width: 120px;
    text-align: center;
    padding: 0.5rem 1rem;
    margin-bottom: 20px;
    border-radius: 5px;
    color: white;
  }

  .error-message {
    width: 720px;
    height: auto;
    text-align: center;
    margin-left: 13px;
    color: red;
    font-weight: bold;
  }

  .table {
    border: 1px solid #ddd;
    box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
    margin: 10px 16px;
    padding: 0 35px;
    border-radius: 8px;
    background-color: white;
    translate: 70px;
    z-index: 0; /* Ensure this is lower than .dropdown-content */
  }

  th {
    font-weight: 600;
    color: #333;
    text-align: center;
    padding: 10px;
  }

  tr {
    text-align: center;
    border-bottom: 1px solid #ddd;
    font-size: smaller;
  }

  th:first-child, th:last-child {
    width: 30%;
    translate: 20px;
  }

  th:nth-child(2) {
    width: 40%;
    font-size: smaller;
    translate: 100px;
  }

  .icon {
    margin: 0 5px;
    cursor: pointer;
    border: 1px solid #ccc;
    border-radius: 50%;
    padding: 4px;
    position: relative;
    background-color: #f9f9f9;
    translate: 150px;
  }
}

@import "../../../../../global.scss";

.findings {
  box-shadow: 2px 2px 4px gray;

  .findingsbtn {
    display: flex;
    justify-content: flex-end;

    .addbtn {
      background-color: $primary-yellow-color;
      color: black;
      font-weight: bold;
      cursor: pointer;
      border: none;
      outline: none;
      padding: 0.5rem 2rem;
      margin: 1rem;
    }

    .savebtn {
      border: none;
      outline: none;
      background-color: $primary-darkblue-color;
      padding: 0.5rem 2rem;
      color: white;
      margin: 1rem;
      cursor: pointer;
    }
  }
}

.findingsTable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  text-align: left;
  font-size: small;
}

.findingsTable td,
.findingsTable thead {
  padding: 10px;
}

.findingsTable tbody td {
  max-width: 60px;
  white-space: normal;
  text-overflow: ellipsis;
  text-align: left;
}

.findingsTable tbody .observations {
  max-width: 70px;
}

.findingsTable tbody .observations .observations-popup {
  max-width: 30px;
}

.findingsTable thead td {
  padding-top: 14px;
  padding-bottom: 14px;
  text-align: left;
  background-color: $primary-darkblue-color;
  color: white;
}

select.typeInput,
input.assignedByInput,
select.statusInput {
  width: 100%;
  /* Ensure it matches the width of the cell */
  box-sizing: border-box;
  /* Include padding and border in the element's total width */
  padding: 4px;
  /* Add padding for better appearance */
  margin: 0;
  /* Remove default margin */
  border: 1px solid #ccc;
  /* Add border for better visibility */
  border-radius: 4px;
  /* Rounded corners */
  height: 30px;
  //position: relative;
}

input.dateInput {
  width: 110%;
  /* Ensure it matches the width of the cell */
  box-sizing: border-box;
  /* Include padding and border in the element's total width */
  padding: 3px;
  /* Add padding for better appearance */
  margin: 0;
  /* Remove default margin */
  border: 1px solid #ccc;
  /* Add border for better visibility */
  border-radius: 4px;
  /* Rounded corners */
  height: 30px;
}

textarea.observationsInput {
  resize: vertical;
  /* Allow vertical resizing */
  height: 40px;
  width: 200px;
  box-sizing: border-box;
  padding: 8px;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

textarea.commentsInput {
  resize: vertical;
  /* Allow vertical resizing */
  height: 40px;
  width: 200px;
  box-sizing: border-box;
  padding: 8px;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
}

.observations-popup {
  display: inline;
  color: blue;
  cursor: pointer;
}

.comments-popup {
  display: inline;
  color: blue;
  cursor: pointer;
}

input.assignedToInput {
  width: 100%;
  box-sizing: border-box;
  padding: 4px;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 30px;
  position: relative;
}
.input-container {
  position: relative;
}
.suggestions {
  position: absolute;
  width: 85%;
  background-color: white;
  border: 1px solid #ccc;
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  box-sizing: border-box;
  border-radius: 4px;
}

/* Individual suggestion style */
.suggestions li {
  padding: 8px;
  /* Padding for each suggestion */
  cursor: pointer;
  /* Pointer cursor on hover */
}

/* Highlight suggestion on hover */
.suggestions li:hover {
  background-color: #eee;
  /* Background color on hover */
}

.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-button {
  margin-left: 10px;
  position: relative;
  bottom: 8px;
  cursor: pointer;
}

.tooltip-box {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 10px;
  border-radius: 5px;
  z-index: 1000;
  white-space: nowrap;
}

.tooltip-box ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.tooltip-box li {
  padding: 5px 0;
}

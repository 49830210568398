.accessManagementContainer {
  width: 100%;
}

.searchHeader {
  background-color: #074173;
  height: 50px;
  border-radius: 8px;
  color: white;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* Ensure the search container is positioned relative */
}

.searchBar {
  width: 30%;
  height: 35px;
  border-radius: 8px;
  border: none;
  padding-left: 10px;
  margin-left: -2%;
  background: #feffee;
}

img {
  width: 27px;
  margin-left: -4%;
}

.userdetailHeader {
  background-color: #074173;
  height: 40px;
  padding-left: 3%;
  border-radius: 8px;
  color: white;
  align-content: center;
}

//added
.userDetails {
  row-gap: 10px;
  justify-content: space-evenly;
  display: grid;
  grid-template-rows: repeat(2, 100px);
  grid-template-columns: repeat(2, 100px);
  height: 260px;
  background: conic-gradient(
    from 90deg at 50% 41.34%,
    #ffffff -91.89deg,
    #82bfc2 75.63deg,
    #e7eb1c 105.13deg,
    #ffffff 268.11deg,
    #82bfc2 435.63deg
  );

.column {
  display: grid;
  row-gap: 30px;
  margin-top: 20px;
  margin-left: -150px;
}
.column-2 {
  display: grid;
  row-gap: 30px;
  margin-top: 20px;
  margin-left: -50px;
}
.form-content {
  display: grid;
  grid-template-columns: repeat(2, 120px);
}

label {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  margin-right: 8px;
  size: 20px;
  color: #4d4d4d;
}
.options {
  padding: 5px;
}
select {
  border-radius: 6px;
  width: 148px;
  height: 32px;
  margin-top: -5%;
}
select > option {
  font-size: 14px;
  padding: 60px;
}
.saveButton {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  align-items: center;
  color: #ffffff;
  background: #074173;
  width: 55%;
  height: 40px;
  border-radius: 8px;
  border: none;
  position: relative;
  left: 90%;
  width: 50%;
  top: 19%;
}
.cancelButton {
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  align-items: center;
  color: #ffffff;
  background: #074173;
  width: 55%;
  height: 40px;
  border-radius: 8px;
  border: none;
  position: relative;
  width: 50%;
  top: -19%;
  margin-left: 10vh;
}
}

.showResult {
  display: grid;
  position: absolute;
  left: 39%;
  top: 22.5%;
  background: #ffff;
  max-height: 32%;
  align-items: start;
  overflow-y: auto;
  padding: 4px 10px;
  border-radius: 8px;
  font-size: 14px;
  width: 25%;
  background: #feffee;
  justify-content: space-evenly;
  z-index: 1000; 
}

.showResult div {
  padding: 10px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
}

.showResult div:hover {
  background-color: #f1f1f1;
}

.showResult span {
  font-family: "Poppins", sans-serif;
}

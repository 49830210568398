@import "../../../../../global.scss";

.history {
  box-shadow: 1px 1px 4px gray;
  overflow-x: hidden;

  .historySearchInputBox {
    background-color: $primary-darkblue-color;
    padding: 1.2rem;
    height: 10px;
    display: flex;
    justify-content: end;
    position: relative;
    align-items: center;
    p {
      color:skyblue;
      position: absolute;
      
    }

    div {
      width: 26%;
      display: flex;
      align-items: center;
      background-color: white;
      border-radius: 7px;
      i {
        font-size: 25px;
        margin-left: 0.3rem;
      }
      input {
        width: 100%;
        outline: none;
        border: none;
        border-radius: 7px;
        padding: 0.5rem;
      }
    }
  }

  .historyBodyContainer {
    position: relative;
    div:first-child {
      box-shadow: 1px 1px 4px gray;
    }
    .historyBtn {
      display: flex;
      justify-content: space-between;
      padding: 1rem;
      color: white;
      margin-top: 0.4rem;
      background-color: #7b9ae4;
      cursor: pointer;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          margin-left: 0.5rem;
          font-size: 20px;
          cursor: pointer;
          transform: translate(-5px, -9px);
        }
      }
    }
    .errorMessage {
      text-align: center;
      color: tomato;
      padding: 1%;
    }
  }
}

.historyTable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  font-size: small;
}

.historyTable td,
.historyTable thead {
  padding: 10px;
}

.historyTable tbody td {
  max-width: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.historyTable thead td {
  padding-top: 14px;
  padding-bottom: 14px;
  text-align: left;
  background-color: rgba(40, 25, 125, 0.989);
  color: white;
}

.historyTable tbody select {
  border: 1px solid #ccc;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
  padding: 0.3rem;
  width: 80%;
}

.historyTable tbody input[type="text"] {
  width: 80%;
  border: 1px solid #ccc;
  outline: none;
  padding: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
}

.historyTable tbody input[type="text"]:focus {
  outline: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 0 0 2px rgba(0, 0, 0, 0.3) inset;
}

.historyTable tbody input[type="radio"] {
  margin-left: 1rem;
}

.historyTable .assessmentBody {
  text-align: left;
}


// @import "../../../../../global.scss";

// .history {
//   box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
//   overflow-x: hidden;

//   .historySearchInputBox {
//     background: linear-gradient(135deg, #1e3c72, #2a5298); // Gradient background
//     padding: 1.2rem;
//     height: 10px;
//     display: flex;
//     justify-content: end;
//     position: relative;
//     align-items: center;
//     p {
//       color: #ffffff; // White text for contrast
//       position: absolute;
//     }

//     div {
//       width: 26%;
//       display: flex;
//       align-items: center;
//       background-color: #ffffff; // White for input background
//       border-radius: 7px;
//       box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
//       i {
//         font-size: 25px;
//         margin-left: 0.3rem;
//         color: #007bff; // Icon color
//       }
//       input {
//         width: 100%;
//         outline: none;
//         border: none;
//         border-radius: 7px;
//         padding: 0.5rem;
//       }
//     }
//   }

//   .historyBodyContainer {
//     position: relative;
//     div:first-child {
//       box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
//     }
//     .historyBtn {
//       display: flex;
//       justify-content: space-between;
//       padding: 1rem;
//       color: white;
//       margin-top: 0.4rem;
//       background: linear-gradient(135deg, #007bff, #0056b3); // Gradient for buttons
//       cursor: pointer;
//       border-radius: 5px; // Rounded corners for modern look
//       transition: background 0.3s ease; // Smooth transition
//       &:hover {
//         background: linear-gradient(135deg, #0056b3, #003f7f); // Darker gradient on hover
//       }
//       div {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         i {
//           margin-left: 0.5rem;
//           font-size: 20px;
//           cursor: pointer;
//           transform: translate(-5px, -9px);
//         }
//       }
//     }
//     .errorMessage {
//       text-align: center;
//       color: #ff4d4d; // Bright red for error messages
//       padding: 1%;
//     }
//   }
// }

// .historyTable {
//   font-family: 'Roboto', sans-serif; // Modern font
//   border-collapse: collapse;
//   width: 100%;
//   text-align: center;
//   font-size: small;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Subtle shadow for depth

//   td,
//   thead {
//     padding: 10px;
//   }

//   tbody td {
//     max-width: 20px;
//     overflow: hidden;
//     white-space: nowrap;
//     text-overflow: ellipsis;
//   }

//   thead td {
//     padding-top: 14px;
//     padding-bottom: 14px;
//     text-align: left;
//     background: linear-gradient(135deg, #28197d, #4f87ef); // Gradient for headers
//     color: white;
//   }

//   tbody select,
//   tbody input[type="text"] {
//     border: 1px solid #ccc;
//     outline: none;
//     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
//     padding: 0.3rem;
//     width: 80%;
//     transition: box-shadow 0.3s ease; // Smooth transition
//     &:focus {
//       box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 0 0 2px rgba(0, 0, 0, 0.3) inset;
//     }
//   }

//   tbody input[type="radio"] {
//     margin-left: 1rem;
//   }

//   .assessmentBody {
//     text-align: left;
//   }
// }






// @import "../../../../../global.scss";

// .history {
//   box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
//   overflow-x: hidden;

//   .historySearchInputBox {
//     background: $primary-darkblue-color;
//     padding: 1.2rem;
//     height: 10px;
//     display: flex;
//     justify-content: end;
//     position: relative;
//     align-items: center;
//     p {
//       color: #ffffff; // White text for contrast
//       position: absolute;
//     }

//     div {
//       width: 26%;
//       display: flex;
//       align-items: center;
//       background-color: #ffffff; // White for input background
//       border-radius: 7px;
//       box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Subtle shadow for depth
//       i {
//         font-size: 25px;
//         margin-left: 0.3rem;
//         color: #6a11cb; // Icon color
//       }
//       input {
//         width: 100%;
//         outline: none;
//         border: none;
//         border-radius: 7px;
//         padding: 0.5rem;
//       }
//     }
//   }

//   .historyBodyContainer {
//     position: relative;
//     div:first-child {
//       box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.2);
//     }
//     .historyBtn {
//       display: flex;
//       justify-content: space-between;
//       padding: 1rem;
//       color: white;
//       margin-top: 0.4rem;
//       background: linear-gradient(135deg, #5bbcdc, #fff175); // Light blue and light yellow gradient for buttons
//       cursor: pointer;
//       border-radius: 5px; // Rounded corners for modern look
//       transition: background 0.3s ease; // Smooth transition
//       &:hover {
//         background: linear-gradient(135deg, #fff175, #5bbcdc); // Reverse gradient on hover
//       }
//       div {
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         i {
//           margin-left: 0.5rem;
//           font-size: 20px;
//           cursor: pointer;
//           transform: translate(-5px, -9px);
//         }
//       }
//     }
//     .errorMessage {
//       text-align: center;
//       color: #ff4d4d; // Bright red for error messages
//       padding: 1%;
//     }
//   }
// }

// .historyTable {
//   font-family: 'Roboto', sans-serif; // Modern font
//   border-collapse: collapse;
//   width: 100%;
//   text-align: center;
//   font-size: small;
//   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); // Subtle shadow for depth

//   td,
//   thead {
//     padding: 10px;
//   }

//   tbody td {
//     max-width: 20px;
//     overflow: hidden;
//     white-space: nowrap;
//     text-overflow: ellipsis;
//   }

//   thead td {
//     padding-top: 14px;
//     padding-bottom: 14px;
//     text-align: left;
//     background: linear-gradient(135deg, #014961, #fff7b3); // Light blue and light yellow gradient for headers
//     color: white;
//   }

//   tbody select,
//   tbody input[type="text"] {
//     border: 1px solid #ccc;
//     outline: none;
//     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(0, 0, 0, 0.1) inset;
//     padding: 0.3rem;
//     width: 80%;
//     transition: box-shadow 0.3s ease; // Smooth transition
//     &:focus {
//       box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 0 0 2px rgba(0, 0, 0, 0.3) inset;
//     }
//   }

//   tbody input[type="radio"] {
//     margin-left: 1rem;
//   }

//   .assessmentBody {
//     text-align: left;
//   }
// }

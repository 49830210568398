@import "../../../global.scss";

.leadershipReportContainer {
  width: 100%;
  overflow-x: hidden;
  select {
    border-radius: 6px;
    height: 36px;
    width: 40%;
    margin-top: -3%;
    margin-left: 1.5%;
  }
}

.shortlabel {
  font-weight: bold;
  font-size: 15px;
  color: $primary-darkblue-color;
}

.app-pageSize {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.chartDiv {
  margin: auto;
  background: #1b2222;
  position: relative;
  margin-top: 40px;
  border-radius: 10px;
  padding-top: 3%;
  display: inline-flex;
  width: 100%;
}

.selectbox {
  height: 40px;
  width: 20%;
  font-weight: bold;
  font-size: 12px;
  margin-left: 1.5%;
  padding-left: 1%;
}
.options {
  padding: 5px;
}

select > option {
  font-size: 14px;
  padding: 60px;
}

button:active {
  background-color:#2f3a74;
}

.leadershipReportContainer .colorList {
  position: absolute;
  left: 76%;
  border: 2px solid #ffffff;
  height: 47%;
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
}

.colorList .redDiv {
  width: 20px;
  height: 20px;
  background-color: #ff0000;
  margin-right: 15px;
}
.colorList .amberDiv {
  width: 20px;
  height: 20px;
  background-color: #ffa500;
  margin-right: 15px;
}
.colorList .greenDiv {
  width: 20px;
  height: 20px;
  background-color: #008000;
  margin-right: 15px;
}

.tstyle {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  text-align: center;
  font-size: small;
  margin-top: 40px;
  border: 1px solid black;
  height: 85%;
  border-radius: 4px;
}

.tstyle td,
.tstyle thead {
  padding: 10px;
}

.tstyle tbody td {
  max-width: 20px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  a {
    text-decoration: none;
    color: blue;
  }
}

.tstyle tbody tr:nth-child(even) {
  background-color: #ddd;
}

.tstyle thead td {
  padding-top: 14px;
  padding-bottom: 14px;
  text-align: center;
  background-color: $primary-darkblue-color;
  color: white;
}

.tstyle thead th:nth-child(4),
.tstyle thead tr:nth-child(1) th:nth-child(1),
.tstyle thead tr:nth-child(2) th:nth-child(1),
.tstyle td:nth-child(1),
.tstyle tbody td:nth-child(4) {
  border-right: 1px solid black;
}
.tstyle thead tr:nth-child(1) {
  border-bottom: 1px solid black;
}

.tstyle thead tr:nth-child(2) {
  border-bottom: 1px solid black;
  background-color: cadetblue;
  color: #ffff;
  line-height: 30px;
}
.tstyle thead tr:nth-child(1) {
  background-color: lightgray;
  line-height: 30px;
}

.btn {
  width: 20vh;
  height: 5vh;
  background-color: #2f3a74;
  margin-left: 88%;
  cursor: pointer;
  color: white;
  margin-top: 5px;
}


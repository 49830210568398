.app {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  font-size: small;
  position: relative;
}

.newQues-box {
  width: 87.5%;
  border: 1px solid #ccc;
  padding: 15px;
  margin: 10px 0;
  box-shadow: 0px 5px 5px #ccc;
  background-color: #d9d9d9;
  font-weight: 50;
}
.text1 {
  color: #2f3a74;
}
.textfield {
  padding: 5px;
  text-align: start;
  font-size: 14px;
  font-weight: 400;
  width: 93%;
  margin-top: 5px;
  margin-bottom: 8px;
}
.textfield1 {
  padding: 5px;
  text-align: start;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  width: 94.3%;
  margin-top: 5px;
  margin-bottom: 8px;
}
.category-section {
  width: 90%;
  font-size: 14px;
  color: #f4f0f0;
  margin-bottom: 2rem;
}
.set-section {
  width: 90%;
  font-size: 14px;
  color: #f4f0f0;
  box-shadow: 0px 5px 5px #ccc;
}

.questionBtn {
  padding: 1rem;
  background-color: #2f3a74;
  color: white;
  display: flex;
  justify-content: space-between;
  box-shadow: 2px 2px 5px white;
  cursor: pointer;
}
.questionBtnn {
  padding: 1rem;
  background-color: #ffb766;
  color: rgb(44, 36, 36);
  display: flex;
  justify-content: space-between;
  box-shadow: 2px 2px 5px white;
  cursor: pointer;
}
.switchTab {
  height: 6vh;
  width: 27vh;
  background-color: #2f3a74;
  color: white;
  cursor: pointer;
  position: absolute;
  bottom: 38px;
  right: 50px;
}
.switchTab:hover {
  background-color: #455292;
}

.question-cell {
  word-wrap: break-word;
  word-break: break-word;
  white-space: normal;
  overflow-y: auto;
  text-align: left;
  width: 300px;
  padding-left: 35px;
}

.question-table tr:nth-of-type(even) {
  background-color: #d9d9d9;
}
.table-row {
  background-color: #f4f0f0;
}

.question-table {
  width: 100%;
  color: #2f3a74;
  margin-bottom: 10px;
  border-collapse: collapse;
}
.head {
  background-color: rgb(133, 120, 90);
  color: #f4f0f0;
}
.question-table th,
.question-table td {
  padding: 10px;
  text-align: left;
}
.saveicon {
  background: none;
  border: none;
  img {
    width: 25px;
    height: 25px;
    cursor: pointer;
    padding-left: 7px;
  }
}

.modifyDelIcon {
  background: none;
  border: none;
  img {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  padding-left: 7px;
}
.response-options {
  margin-bottom: 8px;
}
.response-options input {
  margin-right: 5px;
}
.response-options label {
  margin-right: 10px;
}
.Addbox {
  height: 6vh;
  width: 30vh;
  background-color: #2f3a74;
  text-align: center;
  color: white;
  padding: 0.7rem 1.5rem;
  margin-right: 3rem;
  cursor: pointer;
  border: none;
  margin-left: 0;
}

.category-section h3 {
  cursor: pointer;
}

.category-section ul {
  list-style-type: none;
  padding: 0;
}
.dropDown {
  width: 50%;
}
.dropDown ul {
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.dropDown li {
  padding: 10px;
  cursor: pointer;
}

.dropDown li.active {
  background-color: #ddd;
}

.dropDown li:hover {
  background-color: #f0f0f0;
}
.noSuggMsg {
  color: red;
  cursor: pointer;
}

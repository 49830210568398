.commentss-overlayy {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.commentss-contents {
  margin: 0 auto;
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  width: 50%;
  max-width: 600px;
  overflow-y: auto;
  max-height: 80%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  position: relative;
  box-sizing: border-box;
}

.comment-close-buttons {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  user-select: none;
}

.comment {
  padding: 10px;
  margin: 10px 0;
  border-bottom: 1px solid #ccc;
}
.comment-header {
  display: flex;
  padding-bottom: 20px;
}
.comment-name {
  font-weight: bold;
  color: #333;
  padding-right: 10px;
}
.comment-date {
  font-style: italic;
  color: #666;
  padding-left: 10px;
}
.comment-text {
  font-size: 14px;
  color: #555;
}

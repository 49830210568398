@import "../../../../global.scss";

form {
  .projectHealthSectionMain {
    .projectHealthSection {
      width: 70%;
      margin: 2rem auto 0.7rem auto;
      background-color: #028391;
      p {
        padding: 1rem 0 0 1.5rem;
        color: white;
        font-weight: bold;
      }
      .projectHealthInputContainer {
        display: flex;
        padding: 1rem 0 2rem 1.5rem;
        div {
          display: flex;
          flex-direction: column;
          flex: 1;
          margin-top: 1rem;
          label {
            font-size: small;
            font-weight: bold;
            color: white;
          }
          input {
            border: 1px solid black;
            border-radius: 0;
            width: 90%;
            margin-top: 1rem;
            padding: 0 0.3rem;
            outline: none;
            height: 30px;
          }
          select {
            width: 93%;
            border: 1px solid black;
            border-radius: 0;
            margin-top: 1rem;
            padding: 0 0.3rem;
            outline: none;
            height: 30px;
          }
        }
      }
    }
  }

  .newAssessmentTabSection {
    padding: 1.5rem;
    .tabsContainer {
      display: flex;
      p {
        cursor: pointer;
        padding: 0.5rem 1rem;
      }
    }
  }
  .newAssessmentForm {
    padding-left: 1rem;
    width: 70%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .archiveProject {
      display: flex;
      justify-content: center;
      margin-top: 2rem;
      div {
        justify-content: center;
        align-items: center;
        .archiveCheckbox {
          margin-right: 0.3rem;
        }
        label {
          font-size: 19px;
          font-weight: bold;
        }
      }
    }

    .newAssessmentFormRow {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;

      .row {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-top: 1rem;
        label {
          font-size: small;
          font-weight: bold;
        }
        input[type="text"],
        input[type="date"],
        select,
        textarea {
          border: 1px solid black;
          border-radius: 0;
          width: 90%;
          margin-top: 1rem;
          padding: 0 0.3rem;
          outline: none;
          height: 30px;
        }
        select {
          width: 93%;
        }
      }
    }
  }
}

// .newAssessmentFormBtn {
//   text-align: center;
//   margin-top: 1rem;
//   padding: 1rem 0 3rem 0;
//   display: flex;
//   justify-content: center;
//   button {
//     height: 8vh;
//     width: 23vh;
//     // background-color: $primary-yellow-color;
//     border: none;
//     text-align: center;
//     font-weight: bold;
//     cursor: pointer;
//     margin-left: 20px;
//     display: flex;
//   align-items: center;
//   justify-content: center
//   }

.newAssessmentFormBtn {
  text-align: center;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
}

.newAssessmentFormBtn button {
  height: 8vh;
  width: 23vh;
  background-color: #ff9800; /* Ensure this is not commented out */
  border: none;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  margin-left: 20px;
}

.archivebtn {
  background-color: $primary-yellow-color;
  padding: 1rem 3rem;
  border: none;
  text-align: center;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
}

.reAssessBtn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  input {
    color: white;
    border: none;
    background-color: #2f3a74;
    padding: 0.2rem 2rem;
    margin-bottom: 0.3rem;
    cursor: pointer;
  }
}

.hide {
  transform: translateY(-100px);
  overflow: hidden;
}

.projectIdInputBoxWithSuggestions {
  position: relative;
  width: 100%;
  .showReviewerResults {
    position: absolute;
    top: 62px;
    width: 93.7%;
    background-color: white;
    border: 1px solid #ccc;
    list-style: none;
    margin: 0;
    padding: 0;
    max-height: 100px;
    overflow-y: auto;
    font-size: 13px;
    box-sizing: border-box;
    box-shadow: 2px 2px 6px rgb(163, 162, 162);
    li {
      padding: 8px;
      cursor: pointer;
    }

    li:hover {
      background-color: #eee;
    }
  }
}
.buttonEdit {
  border: none;
  font-size: 15px;
  padding: 1rem 3rem;
  background-color: #ff9800;
  cursor: pointer;
  margin-left: -24px;
}

.handleEditButton {
  padding-top: 10px;
}

.modal_box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50vw;
  height: 60vh;
  margin: auto;
  transform: translateY(5rem);
}

.modal_box .email-field {
  width: 90%;
  height: 90%;
  background-color: #028391;
}
//for the notify button
.notify {
  width: 20vh;
  height: 5vh;
  background-color: #2f3a74;
  margin-left: 88%;
  cursor: pointer;
  color: white;
  margin-top: 5px;
  border: none;
}

//for the multiple email field inside the box
.email-field .custom {
  width: 85%;
  height: 50%;
  margin: auto;
  border: 2px solid black;
}

//for the email id: text in the box
.modal_box .email-field .notifyEmail {
  display: flex;
  justify-content: center;
  color: #eee;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

//for the buttons inside the box
.email-field .button-email {
  display: flex;
  justify-content: space-evenly;
  margin-top: 1rem;
}

.cross {
  width: 25vh;
  height: 5vh;
  background-color: #2f3a74;
  color: #eee;
  border: 2px solid black;
}

.save-email {
  width: 25vh;
  height: 5vh;
  text-align: center;
  color: #eee;
  background-color: #2f3a74;
  border: 2px solid black;
}
